// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import for Firebase Authentication
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAHRhokxKTBDdkP7dP-NScIMWTWju7QL04",
  authDomain: "lifttrackerlog.firebaseapp.com",
  projectId: "lifttrackerlog",
  storageBucket: "lifttrackerlog.appspot.com",
  messagingSenderId: "226013600119",
  appId: "1:226013600119:web:d02ac2fa2ef168c0b1e972",
  measurementId: "G-2XD32MGXTL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = () => getAnalytics(app);    
const auth = getAuth(app); // Initialize Firebase Authentication

export { auth }; // Export the auth object for use in your app
export const firestore = getFirestore(app); // Initialize and export Firestore